html {
  scroll-behavior: smooth;
}

.calendar-container {
  position: relative;
  height: 100%;
  width: 100%;
}

.calendar-container::before {
  content: "";
  background-image: url(../../../brand-logo.png);
  background-attachment: fixed;
  background-size: 354px 99px;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.15;
} 

.header-sticky {
  position: sticky;
  top: 0;
}

.calendar-table {
  font-size: 1.05rem;
}
.calendar-table th {
  padding: 0.35rem !important;
}

.calendar-table td {
  padding: 0.55rem !important;
}

.flex-container {
  display: flex;
}
