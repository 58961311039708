.mobile-container {
  background-color: #343a40;
  color: white;
  padding-bottom: 20px;
}

/* #download-btn {
  position: fixed;
  left: 10%;
  bottom: 5%;
  z-index: 999999;
} */

.react-tabs {
  -webkit-tap-highlight-color: transparent;
  width: 100%;
}

.react-tabs__tab-list {
  border-bottom: 1px solid #aaa;
  padding: 20px 0 0 0;
}

.react-tabs__tab {
  display: inline-block;
  border: 1px solid transparent;
  border-bottom: none;
  bottom: -1px;
  position: relative;
  list-style: none;
  cursor: pointer;

  font-weight: 500;
  font-size: 1.15rem;
  width: 20%;
  padding-top: 15px;
  padding-bottom: 15px;
  text-align: center;
}

.react-tabs__tab--selected {
  background: #fff;
  border-color: #aaa;
  color: black;
  border-radius: 5px 5px 0 0;
  background-color: #f2272f;
}

.react-tabs__tab--disabled {
  color: GrayText;
  cursor: default;
}

.react-tabs__tab:focus {
  box-shadow: 0 0 5px hsl(208, 99%, 50%);
  border-color: hsl(208, 99%, 50%);
  outline: none;
}

.react-tabs__tab:focus:after {
  content: "";
  position: absolute;
  height: 5px;
  left: -4px;
  right: -4px;
  bottom: -5px;
  background: #fff;
}

.react-tabs__tab-panel {
  display: none;
}

.react-tabs__tab-panel--selected {
  display: block;
}
