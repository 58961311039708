.mobile-panel {
  font-size: 1.3rem;
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mobile-table {
  color: white !important;
}
 
.mobile-panel::before {
  content: "";
  background-image: url(../../../brand-logo.png);
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.15;
}

tr > span, span {
  position: relative;
}

/**
* ----------------------------------------------
* Accordion styles
* ----------------------------------------------
**/
.accordion {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  font-size: 1.3rem;
}

.accordion__item + .accordion__item {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.accordion__button {
  background-color: rgba(255, 255, 255, 0.05);
  color: white;

  /*   background-color: #f4f4f4;
  color: #444; */
  cursor: pointer;
  padding: 18px;
  width: 100%;
  text-align: left;
  border: none;
}

.accordion__button:hover {
  /*   background-color: #ddd; */
  background-color: #f2272f;
}

[hidden] {
  display: none;
}

.accordion__panel {
  padding: 20px;
  animation: fadein 0.35s ease-in;
}

/* -------------------------------------------------- */
/* ---------------- Animation part ------------------ */
/* -------------------------------------------------- */

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
