.day div tr {
  background-color: transparent !important;
}

.day tr {
  background-color: transparent !important;
}

td {
    position: relative;
}