.rbc-btn-group > button {
  padding: 0.375rem 0.75rem;
}

.rbc-btn-group > button,
.rbc-toolbar button.rbc-active,
.rbc-toolbar button:active {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.rbc-toolbar button:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}

/**
Week view section customization
**/

/* Make week time header take full height available*/
.rbc-time-header {
  height: 100%;
}

/*Hide time slot labels*/
.rbc-time-view .rbc-label {
  display: none !important;
}

/* Hide time slots per week and day */
.rbc-time-view .rbc-time-content {
  display: none !important;
}
